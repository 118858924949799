<template>
  <div>
    <div class="row">
      <div class="col m-auto">
        <a-button
          icon="book"
          @click="showModal(1)"
          class="m-2"
          style="width: 98%"
        >
          {{ $t("actualite.ajouterActualite") }}
        </a-button>
      </div>
      <div class="col m-auto">
        <a-button
          icon="camera"
          @click="showModal(2)"
          class="m-2"
          style="width: 98%"
        >
          {{ $t("actualite.ajouterAlbum") }}
        </a-button>
      </div>
    </div>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="
        isAlbum
          ? $t('actualite.ajouterAlbum')
          : $t('actualite.ajouterActualite')
      "
      :width="isAlbum ? 800 : 1000"
      :height="1200"
      :footer="false"
    >
      <a-form
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
        @submit="addNews"
      >
        <a-form-item :label="$t('actualite.titre')">
          <a-input
            :placeholder="$t('actualite.titre')"
            v-decorator="[
              'title',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.titre'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('actualite.date')">
          <a-date-picker
            class="full"
            :placeholder="$t('actualite.date')"
            show-time
            format="DD/MM/YYYY HH:mm:ss"
            v-decorator="[
              'plannifDate',
              {
                initialValue: moment(), // Set initial value to current date using moment.js
                rules: [
                  {
                    required: true,
                    message: $t('requis.date'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item v-if="!isAlbum" :label="$t('actualite.categorie')">
          <a-input
            type="string"
            v-decorator="[
              'category',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.categorie'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('actualite.cibles')">
          <a-select
            mode="multiple"
            @change="
              (val) => {
                if (!val.includes('parents')) selectedStudents = [];
              }
            "
            v-decorator="[
              'target',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.cible'),
                  },
                ],
              },
            ]"
          >
            <a-select-option value="all">
              {{ $t("actualite.tous") }}
            </a-select-option>
            <a-select-option value="teacher">
              {{ $t("actualite.enseignants") }}
            </a-select-option>
            <a-select-option value="students">
              {{ $t("actualite.eleves") }}
            </a-select-option>
            <a-select-option value="administration">
              {{ $t("actualite.administration") }}
            </a-select-option>
            <a-select-option value="parents">
              {{ $t("actualite.parents") }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <div
          v-if="
            form.getFieldValue('target') &&
            form.getFieldValue('target').includes('parents')
          "
          style="text-align: center"
        >
          <a-tag color="blue" class="mb-4">{{
            $t("actualite.specifiedInfo")
          }}</a-tag>
          <a-form-item :label="$t('actualite.specificFor')">
            <a-select
              default-value="all"
              @change="
                () => {
                  selectedStudents = [];
                }
              "
              v-decorator="[
                'specificFor',
                {
                  initialValue: 'all',
                  rules: [{ required: true }],
                },
              ]"
            >
              <a-select-option
                value="all"
                @change="
                  () => {
                    selectedStudents = [];
                  }
                "
              >
                {{ $t("actualite.tous") }}
              </a-select-option>
              <a-select-option value="students">
                {{ $t("actualite.students") }}
              </a-select-option>
              <a-select-option value="levels">
                {{ $t("actualite.levels") }}
              </a-select-option>
              <a-select-option value="classrooms">
                {{ $t("actualite.classrooms") }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              form.getFieldValue('specificFor') &&
              form.getFieldValue('specificFor') == 'levels'
            "
            :label="$t('actualite.levels')"
          >
            <a-select
              :placeholder="$t('enseignant.listeNiveaux')"
              mode="multiple"
              v-decorator="[
                'levels',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('enseignant.niveauxRequis'),
                    },
                  ],
                },
              ]"
            >
              <a-select-option value="1">
                {{ $t("niveau.niveau1") }}
              </a-select-option>
              <a-select-option value="2">
                {{ $t("niveau.niveau2") }}
              </a-select-option>
              <a-select-option value="3">
                {{ $t("niveau.niveau3") }}
              </a-select-option>
              <a-select-option value="4">
                {{ $t("niveau.niveau4") }}
              </a-select-option>
              <a-select-option value="5">
                {{ $t("niveau.niveau5") }}
              </a-select-option>
              <a-select-option value="6">
                {{ $t("niveau.niveau6") }}
              </a-select-option>
              <a-select-option value="7">
                {{ $t("niveau.niveau7") }}
              </a-select-option>
              <a-select-option value="8">
                {{ $t("niveau.niveau8") }}
              </a-select-option>
              <a-select-option value="9">
                {{ $t("niveau.niveau9") }}
              </a-select-option>
              <a-select-option value="10">
                {{ $t("niveau.niveau10") }}
              </a-select-option>
              <a-select-option value="11">
                {{ $t("niveau.niveau11") }}
              </a-select-option>
              <a-select-option value="12">
                {{ $t("niveau.niveau12") }}
              </a-select-option>
              <a-select-option value="13">
                {{ $t("niveau.niveau13") }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              form.getFieldValue('specificFor') &&
              form.getFieldValue('specificFor') == 'classrooms'
            "
            :label="$t('actualite.classrooms')"
          >
            <a-select
              :placeholder="$t('actualite.classrooms')"
              mode="multiple"
              v-decorator="[
                'classrooms',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('choisir.classe'),
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="classe in classes"
                :key="classe._id"
                :value="classe._id"
              >
                {{ classe.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              form.getFieldValue('specificFor') &&
              form.getFieldValue('specificFor') == 'students'
            "
            :label="$t('actualite.students')"
          >
            <multiselect
              v-model="selectedStudents"
              :placeholder="$t('sms.selectionnerEleves')"
              :selectLabel="$t('sms.pressEnterToSelect')"
              :options="studentList"
              :custom-label="customLabel"
              :close-on-select="false"
              :preserve-search="true"
              :maxHeight="700"
              label="_id"
              track-by="_id"
              :preselect-first="false"
              :multiple="true"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="align-text-top">
                    {{ props.option.lastName + " " + props.option.firstName }}
                  </span>
                  <br /><span class="option__small font-size-12 text-warning"
                    >Parent: {{ props.option.parent.father.firstName }}
                    {{ props.option.parent.father.lastName }} et
                    {{ props.option.parent.mother.firstName }}
                    {{ props.option.parent.mother.lastName }}</span
                  >
                </div>
              </template>
            </multiselect>
          </a-form-item>
        </div>
        <a-form-item v-if="!isAlbum" :label="$t('actualite.description')">
          <quill-editor v-model="description" :options="editorOptions" />
        </a-form-item>
        <a-form-item :label="$t('actualite.photos')">
          <a-upload-dragger
            v-if="!isAlbum"
            :file-list="photos"
            :before-upload="beforeUploadFiles"
            multiple
            :custom-request="
              () => {
                return true;
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">{{ $t("actualite.cliquer") }}</p>
            <p class="ant-upload-hint">
              {{ $t("actualite.support") }}
            </p>
          </a-upload-dragger>
          <div class="clearfix" v-else>
            <a-upload
              list-type="picture-card"
              :file-list="photos"
              :before-upload="beforeUploadFiles"
              multiple
              :custom-request="
                () => {
                  return true;
                }
              "
              @preview="handlePreview"
              @change="handleChange"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
        </a-form-item>

        <a-progress
          :stroke-color="{
            from: '#108ee9',
            to: '#87d068',
          }"
          v-show="uploadPercentage > 0"
          :percent="uploadPercentage"
          status="active"
        />
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loading"
            :disabled="loading"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button key="back" @click="handleCancel" :disabled="loading">
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */

import apiClient from "@/services/axios";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import "quill/dist/quill.snow.css";
import moment from "moment";

Quill.register("modules/magicUrl", MagicUrl);

export default {
  name: "add actualité",
  components: {
    quillEditor,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      uploadPercentage: 0,
      visibleModal: false,
      form: this.$form.createForm(this),
      loading: false,
      photos: [],
      description: "",
      studentList: [],
      classes: [],
      selectedStudents: [],
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],

            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
          ],
          magicUrl: true, 
        },
      },
      isAlbum: false,
    };
  },
  created() {
    apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
      })
      .then(({ data }) => {
        this.studentList = data;
      });

    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));
  },
  methods: {
    customLabel({ firstName, lastName }) {
      return `${lastName} ${firstName}`;
    },
    showModal(type) {
      if (type == 2) this.isAlbum = true;
      else this.isAlbum = false;

      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
      this.photos = [];
      this.uploadPercentage = 0;
    },
    moment,
    addNews(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loading = true;

          const formData = new FormData();
          if (this.photos.length > 0) {
            for (const file of this.photos) {
              if (this.isAlbum) formData.append("photos", file.originFileObj);
              else formData.append("photos", file);
            }
          }
          formData.append("desc", this.description);
          formData.append("title", values.title);
          formData.append("plannifDate", values.plannifDate);
          for (var i = 0; i < values.target.length; i++) {
            formData.append("target[]", values.target[i]);
          }

          if (this.isAlbum) formData.append("category", "album");
          else formData.append("category", values.category);

          if (values.specificFor === "students") {
            for (const student of this.selectedStudents) {
              formData.append("students[]", student._id);
            }
          } else if (values.specificFor === "levels") {
            for (const level of values.levels) {
              formData.append("levels[]", level);
            }
          } else if (values.specificFor === "classrooms") {
            for (const classroom of values.classrooms) {
              formData.append("classrooms[]", classroom);
            }
          }

          apiClient({
            method: "put",
            url: "/actuality",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },

            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          })
            .then((res) => {
              // res.data.createdAt = moment(this.res.data.createdAt).format("DD/MM/YYYY HH:mm")
              this.$message.success(this.$t("success.pubAjout"));
              this.$emit("newsAdded", res.data);
              this.handleCancel();
            })
            .catch((e) => this.$message.error(this.$t("error.impoPubAjout")))
            .finally(() => (this.loading = false));
        }
      });
    },
    beforeUploadFiles(file, _) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(this.$t("error.fichiers"));
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t("error.image"));
      }
      const islt15 = this.photos.length < 15;
      if (!islt15) {
        this.$message.error(this.$t("error.maxImage"));
      }

      let maxSize = 0;
      this.photos.forEach((f) => {
        maxSize += f.size;
      });
      maxSize += file.size;
      maxSize = maxSize / 1024 / 1024;
      if (maxSize > 50) {
        this.$message.error(this.$t("error.image50"));
      }

      if (isJpgOrPng && islt15 && maxSize <= 50 && isLt2M) {
        this.$message.success(
          this.$t("success.imageTelecharge", { name: file.name })
        );
        this.photos.push(file);
      }
      return isJpgOrPng && islt15 && maxSize <= 50 && isLt2M;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(e) {
      if (e.file) {
        const isJpgOrPng =
          e.file.type === "image/jpeg" || e.file.type === "image/png";

        const isLt2M = e.file.size / 1024 / 1024 < 5;

        const islt15 = this.photos.length < 15;

        let maxSize = 0;
        this.photos.forEach((f) => {
          maxSize += f.size;
        });
        maxSize += e.file.size;
        maxSize = maxSize / 1024 / 1024;

        if (isJpgOrPng && islt15 && maxSize <= 50 && isLt2M) {
          this.photos = e.fileList;
          e.file.status = "done";
        }
      }
    },
  },
};
</script>
<style>
.ql-editor {
  height: 100px;
}
.ql-tooltip {
  z-index: 3;
}
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.full {
  width: 100%;
}
</style>
